import React, { useState } from "react";

function TextField(props) {

    const [email, setEmail] = useState("");

    function onEmailChange(event) {
        setEmail(event.target.value);
    }

    function buttonClick() {
        props.onEmailSubmit(email);
        setEmail("");
    }
    
    return <div>
        <input onChange={onEmailChange} value={email} type="text" className="emailTextfield" placeholder="Email..."/>
        <button className="submitButton" onClick={buttonClick}>Join</button>
        <p style={{ color: "white", padding: "20px" }}>Coming soon. Join the waitlist.</p>
    </div>
}

export default TextField;