import React from "react";

function Header() {
    return <div className="header">
        <h1 className="headerPadding">Design To Code</h1>
        <h2 className="headerPadding">Convert UI Images To Code</h2>
        <h2 className="headerPadding">Using State-Of-Art AI</h2>
        <h3 className="headerPadding">Works with any languages: JavaScript, React, Swift, Angular, Kotlin etc...</h3>
    </div>
}

export default Header;