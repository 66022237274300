import React, { useState } from "react";
import Header from "./components/Header";
import TextField from "./components/Textfield";
import mixpanel from "mixpanel-browser";

function App() {

    mixpanel.init("2ceb451d4967ef879448a7b6517c1c01", { debug: true, track_pageview: true, persistence: 'localStorage' });

    const [success, setSuccess] = useState(false);

    function addEmail(email) {
        console.log(email);
        if (email.includes("@")) {
            mixpanel.track("Waitlist Signup", {
                "Email" : email
            })
            setSuccess(true);
        }
    }

    return <div>
        <Header />
        {(success) && <h1 style={{color: "rgb(0,122,255)"}}>Success joining the waitlist!</h1>}
        <TextField onEmailSubmit={addEmail}/>
    </div>
}

export default App;